/**
 * THEMES.DARK
 */



$base-bg:                       #2d2e2e;

$primary-color:                 #e0a80d;

$text-color:                    #979899;
$heading-color:                 lighten($text-color, 5%);

$link-color:                    $primary-color;
$link-color--hover:             lighten($link-color, 50%);

$base-border-color:             #404242;

$brand-title-first-word-color:  lighten($text-color, 5%);
$brand-title-second-word-color: $primary-color;
$brand-sub-title-color:         $text-color;

$icon-color:                    darken($text-color, 25%);

$image-overlay-bg:              $base-bg;
$image-overlay-text-color:      lighten($text-color, 30%);
$image-overlay-hover-opacity:   .4;
$image-overlay-deco-line:       lighten($base-border-color, 50%);

$social-button-color:           darken($text-color, 15%);
$social-button-bg:              lighten($base-bg, 7%);
$social-button-color--hover:    $primary-color;
$social-button-bg--hover:       darken($social-button-bg, 5%);

$section-base-bg:               $base-bg;
$section-header-bg:             darken($section-base-bg, 0.8%);
$section-header-bg--darker:     darken($section-base-bg, 2%);
$section-content-bg:            $section-base-bg;
$section-header-bg--even:       darken($section-header-bg, .6%);
$section-content-bg--even:      darken($section-content-bg, .8%);



@import "themes.main";